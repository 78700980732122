import React, { useState, useEffect, useMemo, useRef } from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import FeaturedArticleBlock from "../components/FlexibleContentSlices/FeaturedArticleBlock/featuredArticleBlock";
import ArticlesList from "../components/BlogArticleSlices/ArticlePanelsBlock/articlePanelsBlock";

const getUniqueCategories = articles => {
  const allCategories = articles.flatMap(
    article => article.data.categories || []
  );
  const uniqueCategories = Array.from(
    new Set(allCategories.map(cat => cat.slug))
  );
  return uniqueCategories;
};

const BlogIndexTemplate = ({ location }) => {
  const categoryRef = useRef(null);

  const getCategoryFromURL = () => {
    const params = new URLSearchParams(location.search);
    return params.get("category") || "All Categories";
  };

  const [selectedCategory, setSelectedCategory] = useState(
    getCategoryFromURL()
  );

  const data = useStaticQuery(BLOG_INDEX_QUERY);
  const templateData = data.prismicBlogIndex.data;
  const allArticles = useMemo(
    () =>
      data.allPrismicBlogArticle.edges.map(edge => ({
        url: edge.node.url,
        data: edge.node.data
      })),
    [data]
  );

  const uniqueCategories = getUniqueCategories(allArticles);
  const [filteredArticles, setFilteredArticles] = useState(allArticles);

  const handleCategoryChange = event => {
    const category = event.target.value;
    setSelectedCategory(category);
    navigate(`${location.pathname}?category=${category}`, {
      state: { shouldScroll: false },
      replace: true
    });
  };

  useEffect(() => {
    if (selectedCategory && selectedCategory !== "All Categories") {
      const result = allArticles.filter(article => {
        if (Array.isArray(article.data.categories)) {
          return article.data.categories.some(
            cat => cat.slug === selectedCategory
          );
        } else if (article.data.categories && article.data.categories.slug) {
          return article.data.categories.slug === selectedCategory;
        }
        return false;
      });
      setFilteredArticles(result);
    } else {
      setFilteredArticles(allArticles);
    }
  }, [selectedCategory, allArticles]);

  useEffect(() => {
    if (location.hash === "#category-section" && categoryRef.current) {
      const marginTop = 50;
      const scrollToPosition = categoryRef.current.offsetTop - marginTop;

      window.scroll({
        top: scrollToPosition,
        behavior: "smooth"
      });
    }
  }, [location.hash]);

  return (
    <Layout
      seoTitle={
        templateData.meta_title.text || templateData.title.text || "Page"
      }
      seoDescription={templateData.meta_description.text}
      location={location}
      hasMenuIcon
    >
      <div className="mt-hh mb-32 flex flex-col justify-center items-center">
        {templateData.title && (
          <h1 className="text-start font-normal text-2xl md:text-4xl mb-6">
            {templateData.title?.text}
          </h1>
        )}

        {templateData.slices &&
          templateData.slices.map((slice, index) => {
            if (slice.slice_type === "featured_article_block") {
              return (
                <div key={index}>
                  <FeaturedArticleBlock slice={slice} />
                </div>
              );
            }
            return null;
          })}

        <div
          ref={categoryRef}
          id="category-section"
          className="relative rounded-full border my-16 outline-none border-primary-black-20 inline-flex px-5 py-2 items-center"
        >
          <span className="text-sm font-light leading-tight capitalize tracking-wide">
            {selectedCategory}
          </span>

          <div className="pointer-events-none ml-3 text-gray-700">
            <svg width="11" height="6" fill="none">
              <path
                stroke="#191818"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.768.602 5.79 5.263a.389.389 0 0 1-.29.136.377.377 0 0 1-.291-.136L1.236.597"
              />
            </svg>
          </div>

          <select
            className="text-sm font-light leading-tight tracking-wide appearance-none absolute inset-0 w-full h-full opacity-0 cursor-pointer capitalize"
            onChange={handleCategoryChange}
            value={selectedCategory}
          >
            <option value="All Categories">All Categories</option>
            {uniqueCategories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        <ArticlesList articles={filteredArticles} />
      </div>
    </Layout>
  );
};

BlogIndexTemplate.propTypes = {
  location: PropTypes.object.isRequired
};

export default BlogIndexTemplate;

const BLOG_INDEX_QUERY = graphql`
  query BlogIndexQuery {
    allPrismicBlogArticle {
      edges {
        node {
          url
          data {
            meta_description {
              text
            }
            meta_title {
              text
            }
            categories {
              slug
            }
            slices {
              ... on PrismicBlogArticleSlicesArticleBlock {
                slice_type
                id
                items {
                  image {
                    fluid(
                      imgixParams: { q: 100 }
                      srcSetBreakpoints: [640, 768, 1024, 1280]
                    ) {
                      base64
                      sizes
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
            title {
              text
              html
            }
            subtitle {
              text
              html
            }
            preview_image {
              fluid(
                imgixParams: { q: 100 }
                srcSetBreakpoints: [640, 768, 1024, 1280]
              ) {
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
                aspectRatio
              }
            }
          }
        }
      }
    }
    prismicBlogIndex {
      uid
      data {
        meta_description {
          text
        }
        meta_title {
          text
        }
        slices {
          ... on PrismicBlogIndexSlicesFeaturedArticleBlock {
            id
            slice_type
            items {
              article_body {
                html
              }
              blog_article_title {
                text
              }
              featured_article {
                text
              }
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280]
                ) {
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
              }
              link {
                url
              }
              link_text {
                text
              }
            }
            primary {
              header {
                text
              }
            }
          }
        }
      }
    }
  }
`;
